.page {
  width: 100%;
  min-height: 100vh;

  .topbar {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    background-color: #E4FDE1;
    box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.3);

    .title {
      display: flex;
      align-items: center;

      .logo {
        cursor: pointer;
        width: 3rem;
        height: 3rem;

        img {
          width: 3rem;
          height: 3rem;
        }
      }

      h1 {
        font-size: 1.3rem;
        text-transform: uppercase;
        margin: 0 auto;
      }

      .menu {
        cursor: pointer;
        transform: scale(0.8);
        svg {
          fill: black;
          color: black;
        }
      }
    }

    .tabs {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.5rem;

      &.hidden {
        display: none;
      }

      .tab {
        font-weight: 300;
        padding: 0.75rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    @media only screen and (min-width: 930px) {
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 0.75rem;

      .title {
        h1 {
          padding: 0 0.75rem;
          font-size: 1.3rem;
        }
        .menu {
          display: none;
        }
      }

      .tabs {
        margin-left: auto;
        flex-direction: row;
        padding-top: 0;

        &.hidden {
          display: flex;
        }

        .tab {
          padding: 1.5rem 0.9rem;
          text-transform: capitalize;
          font-size: 1.1rem;

          &:hover {
            background-color: darken($color: #E4FDE1, $amount: 10);
          }

          &.active {
            font-weight: 500;
          }
        }
      }
    }
  }
}
