.home {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
    color: hsl(114, 88%, 24%);
    font-weight: bold;
  }
}

.banner {
  width: 100%;
  img {
    max-width: 100%;
    min-height: 30vh;
    object-fit: cover;

    @media screen and (min-width: 775px) {
      max-height: 75vh;
      width: 100%;
    }
  }
}

.motto {
  padding: 1rem;
  background-color: #e4fde1;
  p {
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
  }
}

.messageFrom {
  background-color: #f0fab3;
  margin: 0 auto;

  .photoWrapper {
    padding: 1rem 0;
    img {
      margin: 0 auto;
      display: block;
      width: 80%;
      max-width: 350px;
    }
  }

  .textContent {
    padding: 0 1rem;

    h3 {
      font-size: 1.3rem;
      text-align: center;
      margin: 1rem 0;
      text-transform: uppercase;
    }

    p {
      text-align: center;
      font-size: 1rem;
      line-height: 1.5;
      padding-bottom: 1rem;
    }
  }

  &.right {
    background-color: #E4FDE1;
  }

  @media screen and (min-width: 775px) {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .photoWrapper {
      padding: 0;
      width: 30%;
      img {
        margin: 0 auto 0 0;
        width: 100%;
      }
    }

    .textContent {
      width: 70%;

      h3 {
        text-align: left;
        margin-top: 0.8rem;
      }

      p {
        text-align: left;
        padding-bottom: 0.5rem;
      }
    }

    &.right {
      background-color: #E4FDE1;
      flex-direction: row-reverse;

      img {
        margin: 0 0 0 auto;
      }
    }
  }
}
