.gallery {
  h1 {
    font-size: 3rem;
    text-align: center;
    margin: 25px auto;
  }

  .photosContainer {
    max-width: calc(max(1024px, 80vw));
    margin: 0 auto;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .photo {
    display: block;
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 25px;
  }
}